import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import searchSummaryIcon from '../Assets/new-landing-page-images/search_summary.png'
import chartSummaryIcon from '../Assets/new-landing-page-images/chart_summary.png'
import lineChartSummaryIcon from '../Assets/new-landing-page-images/line_chart_summary.png'

const ProductSummary = () => {
  return (
    <div className='productSummary py-4 py-lg-5' id="product_overview">
        <Container>
            <div className="nfromedGuideHeader text-center mb-5 mx-auto mt-lg-4" style={{ maxWidth: '720px' }}>
                <h2>Choose Your Next Product with Confidence.</h2>
                <p>Leverage AI-driven insights to expand your product portfolio, without the analysis paralysis of manual research.</p>
            </div>
            <Row className='g-3 py-lg-5 mb-lg-4'>
                <Col lg="4">
                    <div className="productSBlock">
                        <img src={searchSummaryIcon} className='img-fluid mb-4 mb-lg-5' alt="search icon" />
                        <span className='pdsbadge mb-3'>Save Time</span>
                        <h4 className='mb-3'>Accelerate Your Product  <br />Research</h4>
                        <p>Nformed consolidates all necessary data into one platform, helping you quickly identify profitable product opportunities without endless searches.</p>
                    </div>
                </Col>
                <Col lg="4">
                    <div className="productSBlock">
                        <img src={chartSummaryIcon} className='img-fluid mb-4 mb-lg-5' alt="search icon" />
                        <span className='pdsbadge mb-3'>Increase Sales</span>
                        <h4 className='mb-3'>Gain Competitive <br />Insights</h4>
                        <p>Track key metrics on your competitors’ ASINs including inventory, pricing, and sales data to stay ahead in the market.</p>
                    </div>
                </Col>
                <Col lg="4">
                    <div className="productSBlock">
                        <img src={lineChartSummaryIcon} className='img-fluid mb-4 mb-lg-5' alt="search icon" />
                        <span className='pdsbadge mb-3'>Stay Informed</span>
                        <h4 className='mb-3'>Understand Market <br /> Trends</h4>
                        <p>Get a clear view of the competitive landscape at the ASIN level, empowering you to make data-driven decisions on sourcing and pricing.</p>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default ProductSummary