import React from 'react'
import UsersIcon from '../Icons/UsersIcon'
import { Container } from 'react-bootstrap';
import { FaStar } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import testimonialOne from '../Assets/new-landing-page-images/testimonial1.png'
import testimonialTwo from '../Assets/new-landing-page-images/testimonial2.png'
import testimonialThree from '../Assets/new-landing-page-images/testimonial3.png'
import testimonialFour from '../Assets/new-landing-page-images/testimonial_4.png'
import testimonialFive from '../Assets/new-landing-page-images/testimonial_5.png'
import testimonialSix from '../Assets/new-landing-page-images/testimonial_6.png'

const NewTestimonial = () => {
  const testimonials = [
    {
      text: "Since using Nformed, we've cut our product research time In half and have seen a significant Increase In our sales. The competitive Insights are Invaluable!",
      name: "Lisa M.",
      company: "PrimeGear Solutions",
      image: testimonialOne
    },
    {
      text: "Nformed's product recommendations have been spot on. We've been able to Identify high-margin products quickly and stay ahead of our competitors.",
      name: "John D.",
      company: "ApexCommerce",
      image: testimonialTwo
    },
    {
      text: "The comprehensive market data and ASIN tracking features have transformed our business strategy. Nformed Is a must-have tool for any serious Amazon seller.",
      name: "Emily R.",
      company: "Velocity Ventures",
      image: testimonialThree
    },
    {
      text: "Nformed has revolutionized our product sourcing process. We've experienced a 40% boost in profitability since implementing their analytics tools.",
      name: "Alex T.",
      company: "NexGen Retail",
      image: testimonialFour
    },
    {
      text: "The market trend forecasts from Nformed have been incredibly accurate. We've successfully launched three new product lines based on their insights.",
      name: "Sarah K.",
      company: "Zenith Merchants",
      image: testimonialFive
    },
    {
      text: "Nformed's competitor analysis feature is a game-changer. We've optimized our pricing strategy and seen a 25% increase in conversion rates.",
      name: "Michael B.",
      company: "Elevate E-Commerce",
      image: testimonialSix
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div className='testiMonial py-5 position-relative' id="testimonial">
      <Container className='py-lg-5'>
        <div className="nfromedGuideHeader text-center mb-5">    
          <h6 className="heroSubHeader">
            <span className='me-1'><UsersIcon/></span>
            Our Customers
          </h6>
          <h2>See What Our <br /> Customers Are Saying</h2>
          <p>Here's what some of our customers say about our platform.</p>
        </div>
        <div className="mt-5 mb-4">
          <Slider {...settings}>
            {testimonials.map((testimonial, index) => (
              <div key={index} className="h-100">
                <div className="testimonialBlock card p-lg-2 mx-2 d-flex flex-column h-100">
                  <div className="card-header testRating">
                    {[...Array(5)].map((_, i) => (
                      <span key={i}><FaStar /></span>
                    ))}
                  </div>
                  <div className="card-body flex-grow-1">
                    <p>"{testimonial.text}"</p>
                  </div>
                  <div className="card-footer">
                    <div className="testImage">
                      <img src={testimonial.image} alt="" className="img-fluid" />
                    </div>
                    <div className="testUserDtl">
                      <h6>{testimonial.name}</h6>
                      <p>{testimonial.company}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </Container>
    </div>
  )
}

export default NewTestimonial
