import React from 'react'
import UsersIcon from '../Icons/UsersIcon'
import { Col, Container, Row } from 'react-bootstrap';

import imageOne from '../Assets/new-landing-page-images/uncover_1.png'
import imageTwo from '../Assets/new-landing-page-images/uncover_2.png'
import imageThree from '../Assets/new-landing-page-images/gain_1.png'
import imageFour from '../Assets/new-landing-page-images/gain_2.png'
import imageFive from '../Assets/new-landing-page-images/gain_3.png'
import imageSix from '../Assets/new-landing-page-images/track_1.png'
import imageSeven from '../Assets/new-landing-page-images/track_2.png'
import imageEight from '../Assets/new-landing-page-images/stay_1.png'
import imageNine from '../Assets/new-landing-page-images/stay_2.png'
import imageTen from '../Assets/new-landing-page-images/stay_3.png'
import { Link } from 'react-router-dom';
import { Parallax } from 'react-scroll-parallax';

const NformedGuide = () => {
  return (
    <div className='NformedGuide py-5 overflow-hidden' id="why_nformed">
        <Container className='py-lg-5'>
            <div className="nfromedGuideHeader text-center mb-5">    
                <h6 className="heroSubHeader">
                    <span className='me-1'><UsersIcon/></span>
                    Struggling to Find the Right Products?
                </h6>
                <h2>Let Nformed Guide You</h2>
            </div>

            <div className="productResearch mb-7">
                <Row className="justify-content-between align-items-center g-3 g-lg-4 g-xxl-5">
                    <Col lg="6" xl="5">
                        <h6 className="heroSubHeader mx-0">
                            <span className='me-1'><UsersIcon/></span>
                            Product Recommendations
                        </h6>
                        <h3>Uncover Winning <br /> Products Quickly</h3>
                        <p>Nformed aggregates data using proprietary AI technology, providing you with actual live numbers, not monthly averages or estimates, allowing you to identify profitable products quickly without manual searches.</p>
                        <Link to="/sign-up" className='btn nformed-btn'>Try Nformed Today</Link>
                    </Col>
                    <Col lg="6" xl="7">
                        <Parallax speed={30} scale={[0.7, 1, 'easeInQuad']}>
                            <div className="psRight d-flex flex-column justify-content-start">
                                {/* <Parallax speed={30} scale={[0.7, 1, 'easeInQuad']}> */}
                                <Parallax translateY={[0, 15, 'easeInQuad']}>
                                        <img src={imageOne} alt="" className="img-fluid" />
                                    {/* <span className='circleBg'></span> */}
                                </Parallax>
                                <Parallax  translateX={[15, 0, 'easeInQuad']}>
                                        <img src={imageTwo} alt="" className="img-fluid" style={{marginTop: '-120px',maxWidth: '80%',marginLeft: '20%'}} />
                                </Parallax>
                            </div>
                        </Parallax>
                    </Col>
                </Row>
            </div>
            <div className="productResearch mb-7">
                <Row className="justify-content-between align-items-center d-flex flex-row-reverse g-3 g-lg-4 g-xxl-5">
                    <Col lg="6" xl="5">
                        <h6 className="heroSubHeader mx-0">
                            <span className='me-1'><UsersIcon/></span>
                            Competitor Research
                        </h6>
                        <h3>Gain an Edge Over Competitors</h3>
                        <p>Monitor key metrics on competitors’ ASINs, including pricing and inventory, to make informed decisions.</p>
                        <Link to="/sign-up" className='btn nformed-btn'>Try Nformed Today</Link>
                    </Col>
                    <Col lg="6" xl="7">
                        <Parallax speed={30} scale={[1, 0.7, 'easeInQuad']}>
                            <div className="psRight d-flex flex-column justify-content-start" style={{maxWidth: '700px'}}>
                                <Parallax speed={30} translateY={[15, 0, 'easeInQuad']}>
                                    <img src={imageThree} alt="" className="img-fluid" />
                                </Parallax>
                                <Parallax translateX={[0, 10, 'easeInQuad']}>
                                    <img src={imageFour} alt="" className="img-fluid" style={{marginTop: '-23%',width: '55%'}} />
                                </Parallax>
                                <Parallax translateX={[0, -10, 'easeInQuad']}>
                                    <img src={imageFive} alt="" className="img-fluid" style={{marginTop: '-20%',width: '70%',marginLeft: '30%'}} />
                                </Parallax>
                            </div>
                        </Parallax>
                    </Col>
                </Row>
            </div>
            <div className="productResearch mb-7">
                <Row className="justify-content-between align-items-center g-3 g-lg-4 g-xxl-5">
                    <Col lg="6" xl="5">
                        <h6 className="heroSubHeader mx-0">
                            <span className='me-1'><UsersIcon/></span>
                            BSR Insights
                        </h6>
                        <h3>Track Top Competitor ASINs Using BSR</h3>
                        <p>Get insights on the best-selling ASINs of your competitors, ranked by Best Sellers Rank (BSR). Identify top products you should consider adding to your store.</p>
                        <Link to="/sign-up" className='btn nformed-btn'>Try Nformed Today</Link>
                    </Col>
                    <Col lg="6" xl="7">
                        <Parallax speed={30} scale={[1, 0.7, 'easeInQuad']}>
                            <div className="psRight d-flex flex-column justify-content-start">
                                <Parallax translateY={[0, 20, 'easeInQuad']}>
                                        <img src={imageSix} alt="" className="img-fluid" />
                                </Parallax>
                                <Parallax  translateX={[0, 20, 'easeInQuad']}>
                                        <img src={imageSeven} alt="" className="img-fluid" style={{marginTop: '-100px',maxWidth: '80%',marginLeft: '20%'}} />
                                </Parallax>
                            </div>
                        </Parallax>
                    </Col>
                </Row>
            </div>
            <div className="productResearch mb-7">
                <Row className="justify-content-between align-items-center d-flex flex-row-reverse g-3 g-lg-4 g-xxl-5">
                    <Col lg="6">
                        <h6 className="heroSubHeader mx-0">
                            <span className='me-1'><UsersIcon/></span>
                            Market Insights
                        </h6>
                        <h3>Stay Ahead of Market <span className='d-xxl-block'>Trends </span> </h3>
                        <p>Understand the competitive landscape and adjust your strategies based on real-time market data.</p>
                        <Link to="/sign-up" className='btn nformed-btn'>Try Nformed Today</Link>
                    </Col>
                    <Col lg="6">
                        <Parallax speed={30} scale={[1, 0.7, 'easeInQuad']}>
                            <div className="psRight d-flex flex-column justify-content-start" style={{maxWidth: '700px'}}>
                                <Parallax speed={30} translateY={[15, 0, 'easeInQuad']}>
                                    <img src={imageEight} alt="" className="img-fluid" />
                                </Parallax>
                                <Parallax translateX={[0, -10, 'easeInQuad']}>
                                    <img src={imageTen} alt="" className="img-fluid" style={{marginTop: '50px',width: '70%',marginLeft: '30%',position: 'relative',zIndex: '-1'}} />
                                </Parallax>
                                <Parallax translateX={[0, 10, 'easeInQuad']}>
                                    <img src={imageNine} alt="" className="img-fluid" style={{marginTop: '-60%',width: '75%'}} />
                                </Parallax>
                            </div>
                        </Parallax>
                    </Col>
                </Row>
            </div>
            <div className="productResearch mb-7">
                <Row className="justify-content-between align-items-center g-3 g-lg-4 g-xxl-5">
                    <Col lg="6">
                        <h6 className="heroSubHeader mx-0">
                            <span className='me-1'><UsersIcon/></span>
                            ASIN Tracking
                        </h6>
                        <h3>Keep Tabs on Your <span className='d-lg-block'>Hunches</span> </h3>
                        <p>Track ASINs you’re interested in to monitor changes in pricing, inventory, and sales velocity over time. This helps you stay updated on key metrics and evolving strategies.</p>
                        <Link to="/sign-up" className='btn nformed-btn'>Try Nformed Today</Link>
                    </Col>
                    {/* <Col lg="6">
                            <Parallax speed={30} scale={[0.7, 1, 'easeInQuad']}>
                                <div className="psRight">
                                        <img src={imageFour} alt="" className="img-fluid" />
                                    <span className='circleBg'></span>
                                    <span className='circleBorder'></span>
                                    <span className='circleBorder two'></span>
                                </div>
                            </Parallax>
                    </Col> */}
                    <Col lg="6">
                        <Parallax speed={30} scale={[1, 0.7, 'easeInQuad']}>
                            <div className="psRight d-flex flex-column justify-content-start" style={{maxWidth: '700px'}}>
                                <Parallax speed={30} translateY={[15, 0, 'easeInQuad']}>
                                    <img src={imageEight} alt="" className="img-fluid" />
                                </Parallax>
                                <Parallax translateX={[0, -10, 'easeInQuad']}>
                                    <img src={imageTen} alt="" className="img-fluid" style={{marginTop: '50px',width: '70%',marginLeft: '30%',position: 'relative',zIndex: '-1'}} />
                                </Parallax>
                                <Parallax translateX={[0, 10, 'easeInQuad']}>
                                    <img src={imageNine} alt="" className="img-fluid" style={{marginTop: '-60%',width: '75%'}} />
                                </Parallax>
                            </div>
                        </Parallax>
                    </Col>
                </Row>
            </div>
        </Container>
    </div>
  )
}

export default NformedGuide