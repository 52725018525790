import React from 'react'
import NewNavbar from '../Components/Navbar/NewNavbar'
import '../NewHome.scss'
import HeroBanner from '../NewComponents/HeroBanner'
import ProductSummary from '../NewComponents/ProductSummary'
import OldAndNew from '../NewComponents/OldAndNew'
import NformedGuide from '../NewComponents/NformedGuide'
import StacksUp from '../NewComponents/StacksUp'
import NewTestimonial from '../NewComponents/NewTestimonial'
import ChoosePlan from '../NewComponents/ChoosePlan'
import FreeTrial from '../NewComponents/FreeTrial'
import NewFooter from '../NewComponents/NewFooter'

const NewHome = () => {
  return (
    <>
        <NewNavbar/>
        <HeroBanner/>
        <ProductSummary/>
        <OldAndNew/>
        <NformedGuide/>
        <StacksUp/>
        <NewTestimonial/>
        <ChoosePlan/>
        <FreeTrial/>
        <NewFooter/>
    </>
  )
}

export default NewHome