import React, { useState, useRef, useEffect  } from 'react'
import UsersIcon from '../Icons/UsersIcon'
import AmazonIcon from '../Icons/AmazonIcon'
import { Link } from 'react-router-dom'
import MakeSellIcon from './../Icons/MakeSellIcon';
import SearchIcon from './../Icons/SearchIcon';
import { Container, Image } from 'react-bootstrap';
import { IoIosPlay } from "react-icons/io";
import PhoneImage from '../Assets/feature_banner.jpg'
import nformedVideo from '../Assets/Nformed-video.mp4'
import amazonImage from '../Assets/amazon_nformed.png'
import heroImage from '../Assets/new-landing-page-images/hero_banner.jpg'
import { Parallax, ParallaxBanner } from 'react-scroll-parallax';
import Ratio from 'react-bootstrap/Ratio';
const HeroBanner = () => {

    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const videoRef = useRef(null);

    useEffect(() => {
        if (isVideoPlaying && videoRef.current) {
            videoRef.current.play();
        }
    }, [isVideoPlaying]);

    const handlePlayButtonClick = () => {
        setIsVideoPlaying(true);
    };

    const handleVideoEnd = () => {
        setIsVideoPlaying(false);
    };

  return (
    <div className={`heroBanner py-2 py-lg-5 ${isVideoPlaying ? 'videoActive' : ''}`}>
        <Container>
        <h6 className="heroSubHeader">
            <span className='me-1'><UsersIcon/></span>
            Built for Amazon 3P Sellers and Resellers
        </h6>
        <div className="makeSalesContainer">
            <h2>Discover <span>Profitable Products</span> Effortlessly</h2>
            <Parallax  translateX={[30, 0]}>
                <div className="makeSales">
                    <span>
                        <MakeSellIcon/>
                    </span>
                    <p>Find More ASIN's</p>
                </div>
            </Parallax>
        </div>
        <div className="exploreCompContainer">
            <h5>The most advanced <strong>AI-powered product recommendation tool </strong> for finding  <br /> the best opportunities and gaining competitive insights.
            </h5>
            <Parallax  translateX={[-30, 0]}>
                <div className="makeSales">
                    <span>
                        <SearchIcon/>
                    </span>
                    <p>Explore Your<br /> Competition</p>
                </div>
            </Parallax>
        </div>
        <Link to="/sign-up" className="btn nformed-btn">Try Nformed Today</Link>
        <h4>Trusted by top Amazon third-party sellers. Start your free trial today.</h4>

        <ParallaxBanner
        layers={[
            { image: heroImage , speed: -60 },
        ]}
        className="aspect-[2/1]"
        >
        </ParallaxBanner>
        
            <div className={`BannerVideoBlock ${isVideoPlaying ? 'p-0' : ''}`}>
            {!isVideoPlaying && (
                <>
                    <div className="bannerVideoContent">
                        <h3>Everything You Need to <br /> Succeed on Amazon   
                            {/* <span><img src={amazonImage} alt="amazon" className='img-fluid' style={{ maxWidth: '100px' }} /></span>  */}
                        </h3>
                        <p>Simplify Your Product Research</p>
                        <button className='btn btnVideoPlay animate__animated animate__pulse animate__infinite' onClick={handlePlayButtonClick}>
                            <IoIosPlay />
                        </button>
                    </div>
                    <Ratio aspectRatio="16x9">
                        <img src={PhoneImage} className='img-fluid phoneImage' />
                    </Ratio>
                    {/* <img src={PhoneImage} className='img-fluid phoneImage' /> */}
                </>
            )}
            {isVideoPlaying && (
                <Ratio aspectRatio="16x9">
                    <video
                        width={'100%'}
                        height={'auto'}
                        controls
                        ref={videoRef}
                        onEnded={handleVideoEnd}
                    >
                        <source src={nformedVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </Ratio>
            )}
            </div>
        </Container>
    </div>
  )
}

export default HeroBanner