import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaCheck } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import { RiQuestionLine } from "react-icons/ri";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import jungleScout from '../Assets/new-landing-page-images/jungleScout.png';
import helium10 from '../Assets/new-landing-page-images/Helium.png';
import nformed from '../Assets/new-landing-page-images/Nformed.png';
import UsersIcon from '../Icons/UsersIcon';
const StacksUp = () => {
  return (
    <section className='stacksUp py-5'>
        <Container className=''>
         <div className="nfromedGuideHeader text-center mb-4 mx-auto mt-lg-4" style={{ maxWidth: '880px' }}>
            <h6 className="heroSubHeader text-white">
              <span className='me-1'><UsersIcon/></span>
              Shopping Around?
            </h6>
            <h2>How  <span>Nformed</span> Stacks Up Against Competitors</h2>
            <p className='text-white mt-4 mb-4'>While tools like Helium 10 and Jungle Scout are geared towards private-label sellers, Nformed is designed specifically for third-party sellers and resellers. We centralize the tools needed for competitive analysis and product research, making it easier to grow your business by selling top items.</p>
          </div>  
          <Row>
              <Col lg="12">
                  <div className="stackTableDiv">
                    <div className="table-responsive bg-white px-3 py-3">
                      <table className='table table-borderless'> 
                        <thead>
                          <tr>
                            <th width="280px"></th>
                            <th className=''><img src={jungleScout} alt="jungleScout" className='w-100' /></th>
                            <th><img src={helium10} alt="helium10" className='w-100' /></th>
                            <th><img src={nformed} alt="nformed" className='w-100' /></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td><span className='fw-bold border-bottom border-end d-block py-2'># of Users</span></td>
                            <td className='ps-0'><span className='border-bottom d-block py-2'>Varies  </span></td>
                            <td><span className='border-bottom d-block py-2'>Varies</span></td>
                            <td><span className='border-bottom d-block py-2'>Varies</span></td>
                          </tr>
                          <tr>
                            <td><span className='fw-bold border-bottom border-end d-block py-2'>AI Driven</span></td>
                            <td className='ps-0'><span className='text-primary border-bottom d-block py-2 ps-4'><FaCheck /></span></td>
                            <td><span className='text-primary border-bottom d-block py-2'><FaCheck /></span></td>
                            <td><span className='text-primary border-bottom d-block py-2'><FaCheck /></span></td>
                          </tr>
                          <tr>
                            <td><span className='fw-bold border-end d-block py-2'>Seller Inventory</span></td>
                            <td className='ps-0'><span className='text-danger border-bottom d-block py-2 ps-4'><IoMdClose /></span></td>
                            <td><span className='text-primary border-bottom d-block py-2'><FaCheck /></span></td>
                            <td><span className='text-primary border-bottom d-block py-2'><FaCheck /></span></td>
                          </tr>
                          <tr>
                            <td><span className='fw-bold border-end d-block py-2'>Sales Pricing</span></td>
                            <td className='ps-0'><span className='text-primary border-bottom d-block py-2 ps-4'><FaCheck /></span></td>
                            <td><span className='text-primary border-bottom d-block py-2'><FaCheck /></span></td>
                            <td><span className='text-primary border-bottom d-block py-2'><FaCheck /></span></td>
                          </tr>
                          <tr>
                            <td><span className='fw-bold border-end d-block py-2'>Sales Quantity</span></td>
                            <td className='ps-0'><span className='text-primary border-bottom d-block py-2 ps-4'><FaCheck /></span></td>
                            <td><span className='text-primary border-bottom d-block py-2'><FaCheck /></span></td>
                            <td><span className='text-primary border-bottom d-block py-2'><FaCheck /></span></td>
                          </tr>
                          <tr>
                            <td><span className='fw-bold border-bottom border-end d-block py-2'>Detailed Seller Info</span></td>
                            <td className='ps-0'><span className='text-danger border-bottom d-block py-2 ps-4'><IoMdClose /></span></td>
                            <td><span className='text-danger border-bottom d-block py-2'><IoMdClose /></span></td>
                            <td><span className='text-primary border-bottom d-block py-2'><FaCheck /></span></td>
                          </tr>
                          <tr>
                            <td>
                              <span className='fw-bold border-bottom border-end d-block py-2'> 
                                <OverlayTrigger
                                  key={'top'}
                                  placement={'top'}
                                  overlay={
                                    <Tooltip id={`tooltip-${'top'}`} className="custom-tooltip">
                                      This is a feature that only applies to OEMs 
                                      Our tool is designs to third-party and resellers.
                                    </Tooltip>
                                  }
                                >
                                  <i><RiQuestionLine /></i>
                                </OverlayTrigger>
                                Product Research
                              </span>
                            </td>
                            <td className='ps-0'><span className='text-danger border-bottom d-block py-2 ps-4'><IoMdClose /></span></td>
                            <td><span className='text-primary border-bottom d-block py-2'><FaCheck /></span></td>
                            <td><span className='text-primary border-bottom d-block py-2'><FaCheck /></span></td>
                          </tr>
                          <tr>
                            {/* <td><span className='fw-bold border-bottom border-end d-block py-2'>Keyword Research</span></td> */}
                            <td>
                              <span className='fw-bold border-bottom border-end d-block py-2'> 
                                <OverlayTrigger
                                  key={'top'}
                                  placement={'top'}
                                  overlay={
                                    <Tooltip id={`tooltip-${'top'}`} className="custom-tooltip">
                                      This is a feature that only applies to OEMs 
                                      Our tool is designs to third-party and resellers.
                                    </Tooltip>
                                  }
                                >
                                  <i><RiQuestionLine /></i>
                                </OverlayTrigger>
                                Keyword Research
                              </span>
                            </td>
                            <td className='ps-0'><span className='text-primary border-bottom d-block py-2 ps-4'><FaCheck /></span></td>
                            <td><span className='text-primary border-bottom d-block py-2'><FaCheck /></span></td>
                            <td><span className='text-danger border-bottom d-block py-2'><IoMdClose /></span></td>
                          </tr>
                          <tr>
                            <td><span className='fw-bold border-bottom border-end d-block py-2'>Listing Optimization</span></td>
                            <td className='ps-0'><span className='text-primary border-bottom d-block py-2 ps-4'><FaCheck /></span></td>
                            <td><span className='text-primary border-bottom d-block py-2'><FaCheck /></span></td>
                            <td><span className='text-danger border-bottom d-block py-2'><IoMdClose /></span></td>
                          </tr>
                          <tr>
                            <td><span className='fw-bold border-bottom border-end d-block py-2'>BSR Data</span></td>
                            <td className='ps-0'><span className='text-danger border-bottom d-block py-2 ps-4'><IoMdClose /></span></td>
                            <td><span className='text-primary border-bottom d-block py-2'><FaCheck /></span></td>
                            <td><span className='text-primary border-bottom d-block py-2'><FaCheck /></span></td>
                          </tr>
                          <tr>
                            <td><span className='fw-bold border-bottom border-end d-block py-2'>Competitor Analysis</span></td>
                            <td className='ps-0'><span className='text-danger border-bottom d-block py-2 ps-4'><IoMdClose /></span></td>
                            <td><span className='text-danger border-bottom d-block py-2'><IoMdClose /></span></td>
                            <td><span className='text-primary border-bottom d-block py-2'><FaCheck /></span></td>
                          </tr>
                          <tr>
                            <td><span className='fw-bold border-bottom border-end d-block py-2'>Buy Box Price Tracking</span></td>
                            <td className='ps-0'><span className='text-danger border-bottom d-block py-2 ps-4'><IoMdClose /></span></td>
                            <td><span className='text-primary border-bottom d-block py-2'><FaCheck /></span></td>
                            <td><span className='text-primary border-bottom d-block py-2'><FaCheck /></span></td>
                          </tr>
                          <tr>
                            <td><span className='fw-bold border-end d-block py-2'>Buy Box Seller Tracking</span></td>
                            <td className='ps-0'><span className='text-danger d-block py-2 ps-4'><IoMdClose /></span></td>
                            <td><span className='text-danger d-block py-2'><IoMdClose /></span></td>
                            <td><span className='text-primary d-block py-2'><FaCheck /></span></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
              </Col>
              <Col lg="12" className='text-center mt-4 mb-lg-4'>
                <Link to="/sign-up" className='btn nformed-btn py-3 px-5 fs-6' style={{borderRadius: '999px'}}>Start Free Trial</Link>
              </Col>
          </Row>
        </Container>
    </section>
  )
}

export default StacksUp
